var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c("form-Component", {
            attrs: { copyData: _vm.insuranceCopy },
            on: {
              "update:copyData": function ($event) {
                _vm.insuranceCopy = $event
              },
              "update:copy-data": function ($event) {
                _vm.insuranceCopy = $event
              },
              save: _vm.validationForm,
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function () {
                  return [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "4", sm: "6" } },
                          [
                            _c("email-phone-fax", {
                              attrs: {
                                emails: _vm.insuranceCopy.paymentEmails,
                                phones: _vm.insuranceCopy.paymentPhones,
                              },
                              on: {
                                "update:emails": function ($event) {
                                  return _vm.$set(
                                    _vm.insuranceCopy,
                                    "paymentEmails",
                                    $event
                                  )
                                },
                                "update:phones": function ($event) {
                                  return _vm.$set(
                                    _vm.insuranceCopy,
                                    "paymentPhones",
                                    $event
                                  )
                                },
                              },
                            }),
                            _c("br"),
                            _c("br"),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "4", sm: "6" } },
                          [
                            _c("address-component", {
                              model: {
                                value: _vm.insuranceCopy.paymentAddress,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.insuranceCopy,
                                    "paymentAddress",
                                    $$v
                                  )
                                },
                                expression: "insuranceCopy.paymentAddress",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "can",
                          { attrs: { I: "read", a: "ClaimRules" } },
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "4", sm: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label:
                                        "Has Electronic Funds Transfer (EFT)",
                                    },
                                  },
                                  [
                                    _c("validation-provider", {
                                      staticClass: "demo-inline-spacing pt-0",
                                      attrs: {
                                        name: "Has Electronic Funds",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var errors = ref.errors
                                            return [
                                              _c(
                                                "b-form-radio",
                                                {
                                                  staticClass: "pt-0 mt-0",
                                                  attrs: {
                                                    name: "Has Electronic Funds",
                                                    value: "true",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.insuranceCopy
                                                        .hasEftOption,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.insuranceCopy,
                                                        "hasEftOption",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "insuranceCopy.hasEftOption",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    Yes\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-form-radio",
                                                {
                                                  staticClass: "pt-0 mt-0",
                                                  attrs: {
                                                    name: "Has Electronic Funds",
                                                    value: "false",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.insuranceCopy
                                                        .hasEftOption,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.insuranceCopy,
                                                        "hasEftOption",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "insuranceCopy.hasEftOption",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    No\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Setup Instructions",
                                      "label-for": "eftInstructions",
                                    },
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "EFT Setup Instructions",
                                        rules:
                                          _vm.insuranceCopy.hasEftOption == true
                                            ? "required"
                                            : "",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "eftInstructions",
                                                  placeholder: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.insuranceCopy
                                                      .eftInstructions,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.insuranceCopy,
                                                      "eftInstructions",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "insuranceCopy.eftInstructions",
                                                },
                                              }),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label:
                                        "Provides ERA (Electronic Remittance Advice)",
                                    },
                                  },
                                  [
                                    _c("validation-provider", {
                                      staticClass: "demo-inline-spacing pt-0",
                                      attrs: {
                                        name: "Has ERA",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var errors = ref.errors
                                            return [
                                              _c(
                                                "b-form-radio",
                                                {
                                                  staticClass: "pt-0 mt-0",
                                                  attrs: {
                                                    name: "Has ERA",
                                                    value: "true",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.insuranceCopy
                                                        .hasEraOption,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.insuranceCopy,
                                                        "hasEraOption",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "insuranceCopy.hasEraOption",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    Yes\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-form-radio",
                                                {
                                                  staticClass: "pt-0 mt-0",
                                                  attrs: {
                                                    name: "Has ERA",
                                                    value: "false",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.insuranceCopy
                                                        .hasEraOption,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.insuranceCopy,
                                                        "hasEraOption",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "insuranceCopy.hasEraOption",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    No\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Setup Instructions",
                                      "label-for": "eraInstructions",
                                    },
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Setup Instructions",
                                        rules:
                                          _vm.insuranceCopy.hasEraOption == true
                                            ? "required"
                                            : "",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "eraInstructions",
                                                  placeholder: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.insuranceCopy
                                                      .eraInstructions,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.insuranceCopy,
                                                      "eraInstructions",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "insuranceCopy.eraInstructions",
                                                },
                                              }),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.formData,
              callback: function ($$v) {
                _vm.formData = $$v
              },
              expression: "formData",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }