var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form-Component",
        {
          attrs: { copyData: _vm.insuranceCopy },
          on: {
            "update:copyData": function ($event) {
              _vm.insuranceCopy = $event
            },
            "update:copy-data": function ($event) {
              _vm.insuranceCopy = $event
            },
            save: _vm.validationForm,
          },
          model: {
            value: _vm.formData,
            callback: function ($$v) {
              _vm.formData = $$v
            },
            expression: "formData",
          },
        },
        [
          _c(
            "template",
            { slot: "body" },
            [
              _c(
                "validation-observer",
                { ref: "simpleRules1" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Eligibility Payer ID",
                                "label-for": "payerID",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "Eligibility Payer ID",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "payerID",
                                            placeholder: "",
                                          },
                                          model: {
                                            value:
                                              _vm.insuranceCopy
                                                .eligibilityPayorId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.insuranceCopy,
                                                "eligibilityPayorId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "insuranceCopy.eligibilityPayorId",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c("email-phone-fax", {
                            attrs: {
                              emails: _vm.insuranceCopy.eligibilityEmails,
                              phones: _vm.insuranceCopy.eligibilityPhones,
                            },
                            on: {
                              "update:emails": function ($event) {
                                return _vm.$set(
                                  _vm.insuranceCopy,
                                  "eligibilityEmails",
                                  $event
                                )
                              },
                              "update:phones": function ($event) {
                                return _vm.$set(
                                  _vm.insuranceCopy,
                                  "eligibilityPhones",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c("address-component", {
                            model: {
                              value: _vm.insuranceCopy.eligibilityAddress,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.insuranceCopy,
                                  "eligibilityAddress",
                                  $$v
                                )
                              },
                              expression: "insuranceCopy.eligibilityAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "mt-5", attrs: { cols: "12" } },
                    [
                      _c("h5", { staticClass: "mb-1" }, [
                        _c("b", [
                          _vm._v("Web Links (Log-In Details And Methods)"),
                        ]),
                      ]),
                      _c("weblinks-cc", {
                        attrs: {
                          webLinksToFormField: "insuranceId",
                          filterByField: "InsuranceId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }