var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          isDataFromModel: true,
          sidebarData: _vm.contact,
          fields: _vm.fields,
          isAddEditSidbar: true,
          itemsRepo: _vm.insuranceContactsRepo,
          sidebarOpen: false,
          copyData: _vm.contactCopy,
        },
        on: {
          "update:sidebarData": function ($event) {
            _vm.contact = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.contact = $event
          },
          "update:copyData": function ($event) {
            _vm.contactCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.contactCopy = $event
          },
          save: _vm.validationForm,
          "on-open": function ($event) {
            _vm.sidebar = true
          },
        },
        scopedSlots: _vm._u([
          {
            key: "cell(lastName)",
            fn: function (data) {
              return [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "pr-0", attrs: { cols: "3" } },
                      [
                        _c("b-avatar", {
                          staticClass: "float-left",
                          attrs: {
                            variant: _vm.getColor(),
                            text: _vm.getName(
                              data.item.firstName + "" + data.item.lastName
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("b-col", { staticClass: "pl-0" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            data.item.firstName + " " + data.item.lastName
                          ) +
                          "\n          "
                      ),
                      _c("br"),
                      _c("span", { staticClass: "grey-text smaller-text" }, [
                        _vm._v(_vm._s(data.item.role)),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.insuranceContacts,
          callback: function ($$v) {
            _vm.insuranceContacts = $$v
          },
          expression: "insuranceContacts",
        },
      }),
      _c("sidebar-component", {
        attrs: { copyData: _vm.contactCopy, open: _vm.sidebar },
        on: {
          "update:copyData": function ($event) {
            _vm.contactCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.contactCopy = $event
          },
          "update:open": function ($event) {
            _vm.sidebar = $event
          },
          save: _vm.validationForm,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "validation-observer",
                  { ref: "simpleRules" },
                  [
                    _c(
                      "b-form",
                      [
                        _c(
                          "b-container",
                          { staticClass: "px-2 mt-1 pb-3" },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  [
                                    _vm.contact.id
                                      ? _c("h4", [_vm._v("Edit Contact")])
                                      : _c("h4", [_vm._v("Add Contact")]),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                staticClass: "mt-2",
                                                attrs: { label: "First Name" },
                                              },
                                              [
                                                _c("validation-provider", {
                                                  attrs: {
                                                    name: "First Name",
                                                    rules: "required",
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              placeholder: "",
                                                              required: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.contactCopy
                                                                  .firstName,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.contactCopy,
                                                                    "firstName",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "contactCopy.firstName",
                                                            },
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                staticClass: "mt-2",
                                                attrs: { label: "Last Name" },
                                              },
                                              [
                                                _c("validation-provider", {
                                                  attrs: {
                                                    name: "Last Name",
                                                    rules: "required",
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              placeholder: "",
                                                              required: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.contactCopy
                                                                  .lastName,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.contactCopy,
                                                                    "lastName",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "contactCopy.lastName",
                                                            },
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      { attrs: { label: "Role" } },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Role",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      placeholder: "",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contactCopy.role,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contactCopy,
                                                          "role",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contactCopy.role",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("email-phone-single", {
                                      attrs: {
                                        sourcePhone: _vm.contactCopy.phone,
                                        sourceEmail: _vm.contactCopy.email,
                                      },
                                      on: {
                                        "update:sourcePhone": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.contactCopy,
                                            "phone",
                                            $event
                                          )
                                        },
                                        "update:source-phone": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.contactCopy,
                                            "phone",
                                            $event
                                          )
                                        },
                                        "update:sourceEmail": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.contactCopy,
                                            "email",
                                            $event
                                          )
                                        },
                                        "update:source-email": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.contactCopy,
                                            "email",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                    _c("br"),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.contact,
          callback: function ($$v) {
            _vm.contact = $$v
          },
          expression: "contact",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }