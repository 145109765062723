var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c("form-Component", {
            attrs: { copyData: _vm.insuranceCopy },
            on: {
              "update:copyData": function ($event) {
                _vm.insuranceCopy = $event
              },
              "update:copy-data": function ($event) {
                _vm.insuranceCopy = $event
              },
              save: _vm.validationForm,
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function () {
                  return [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "4", sm: "6" } },
                          [
                            _c("email-phone-fax", {
                              attrs: {
                                emails: _vm.insuranceCopy.appealEmails,
                                phones: _vm.insuranceCopy.appealPhones,
                              },
                              on: {
                                "update:emails": function ($event) {
                                  return _vm.$set(
                                    _vm.insuranceCopy,
                                    "appealEmails",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "4", sm: "6" } },
                          [
                            _c("address-component", {
                              model: {
                                value: _vm.insuranceCopy.appealAddress,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.insuranceCopy,
                                    "appealAddress",
                                    $$v
                                  )
                                },
                                expression: "insuranceCopy.appealAddress",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.formData,
              callback: function ($$v) {
                _vm.formData = $$v
              },
              expression: "formData",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }