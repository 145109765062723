var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-col", { staticClass: "mt-1 pl-2", attrs: { cols: "12" } }, [
        _c("h5", [
          _c("b", [_vm._v("Procedures That Have Other Required Modifiers")]),
        ]),
      ]),
      _c("table-component", {
        attrs: {
          "load-params": { insuranceId: _vm.$route.params.id },
          "load-func-name": "LoadByInsuranceReqMods",
          fields: _vm.fields,
          "copy-data": _vm.procedureSettingsCopy,
          "sidebar-data": _vm.selectedProcSetting,
          "is-add-edit-sidbar": true,
          "sidebar-open": _vm.sidebar,
          "do-save": true,
          "items-repo": _vm.procedureSettingsRepo,
          "is-data-from-model": false,
        },
        on: {
          "update:copyData": function ($event) {
            _vm.procedureSettingsCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.procedureSettingsCopy = $event
          },
          "update:sidebarData": function ($event) {
            _vm.selectedProcSetting = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.selectedProcSetting = $event
          },
          "update:sidebarOpen": function ($event) {
            _vm.sidebar = $event
          },
          "update:sidebar-open": function ($event) {
            _vm.sidebar = $event
          },
          "on-before-save": _vm.onBeforeSave,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(requiredModifiers)",
            fn: function (data) {
              return [
                _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        data.item.modifiers
                          .filter(function (x) {
                            return data.item.requiredModifierSettings.includes(
                              x.id
                            )
                          })
                          .map(function (x) {
                            return x.code
                          })
                          .join(" , ")
                      ) +
                      "\n      "
                  ),
                ]),
              ]
            },
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "b-container",
                  { staticClass: "mt-1" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _vm.procedureSettingsCopy.id
                              ? _c("h4", [
                                  _vm._v("Edit Other Required Modifiers"),
                                ])
                              : _c("h4", [
                                  _vm._v("Add Other Required Modifiers"),
                                ]),
                            _c("h6", { staticClass: "mt-2" }, [
                              _vm._v(
                                "\n              Procedures that have other required modifiers\n            "
                              ),
                            ]),
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mt-2",
                                attrs: { label: "Procedure Code" },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Procedure Code",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("infinit-select", {
                                            attrs: {
                                              repo: _vm.cptRepo,
                                              "include-ids": [
                                                _vm.procedureSettingsCopy.cptId,
                                              ],
                                              label: "procCode",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.procedureSettingsCopy.cptId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procedureSettingsCopy,
                                                  "cptId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procedureSettingsCopy.cptId",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-form-group",
                              { attrs: { label: "Other Required Modifiers" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Other Required Modifiers",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("infinit-select", {
                                            ref: "requiredModifiers",
                                            attrs: {
                                              repo: _vm.modifiersRepo,
                                              "include-ids":
                                                _vm.procedureSettingsCopy
                                                  .requiredModifierSettings,
                                              label: "code",
                                              selectable: function (x) {
                                                return x.status == "Active"
                                              },
                                              multiple: "",
                                              placeholder: "Enter ...",
                                            },
                                            model: {
                                              value:
                                                _vm.procedureSettingsCopy
                                                  .requiredModifierSettings,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procedureSettingsCopy,
                                                  "requiredModifierSettings",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procedureSettingsCopy.requiredModifierSettings",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }