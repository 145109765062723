




import InsuranceCompanyMoreTabCC from '/src/class-components/insurance/InsuranceCompanyMoreTabCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    InsuranceCompanyMoreTabCC
  }
})
export default class InsuranceCompanyDetails extends Vue {}
