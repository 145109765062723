var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "tabs-component",
            {
              staticClass: "pl-2 pt-2",
              attrs: {
                "base-route": {
                  name: "ins-company-details",
                  params: { id: _vm.$route.params.id },
                },
                pills: "",
              },
            },
            [
              _c(
                "b-tab",
                {
                  attrs: { active: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" Details")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("DetailsCC", {
                    on: { save: _vm.save },
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  attrs: { lazy: "", disabled: !_vm.allowed },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" Plans")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [_c("PlansCC")],
                1
              ),
              _c(
                "b-tab",
                {
                  attrs: { lazy: "", disabled: !_vm.allowed },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" Contacts")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [_c("ContactsCC")],
                1
              ),
              _c(
                "b-tab",
                {
                  attrs: {
                    disabled: !_vm.allowed,
                    to: {
                      name: "ins-company-details-eligibility-&-claims",
                      params: { id: _vm.$route.params.id },
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" Eligibility & Claims")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("EligibilityClaimsMoreTabCC", {
                    on: { save: _vm.save },
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                ],
                1
              ),
              _c(
                "can",
                { attrs: { I: "read", a: "ClaimRules" } },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { lazy: "", disabled: !_vm.allowed },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v(" Overwritten Proc.")]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [_c("OverwrittenProcCC")],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        lazy: "",
                        disabled: !_vm.allowed,
                        to:
                          "/ins-companies/" +
                          _vm.$route.params.id +
                          "/authorizations",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v(" Authorizations")]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [_c("AuthorizationsMoreTabCC")],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  attrs: {
                    lazy: "",
                    disabled: !_vm.allowed,
                    to: "/ins-companies/" + _vm.$route.params.id + "/mappings",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" Mappings")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [_c("mappings-tab")],
                1
              ),
              _c("b-tab", {
                attrs: { disabled: !_vm.allowed },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [_vm._v(" Activity & Notes")]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }